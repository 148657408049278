import {HttpInterceptorFn, HttpResponse} from '@angular/common/http';
import {tap} from 'rxjs/operators';

export const httpResponseTimeLogger: HttpInterceptorFn = (req, next) => {
  const startTimestamp = new Date().getTime();

  return next(req).pipe(
    tap(event => {
      if (event instanceof HttpResponse) {
        const endTimestamp = new Date().getTime();
        if ( ((endTimestamp - startTimestamp) / 1000) > 0 ) {
          console.warn(`API ${req.urlWithParams} respond ${(endTimestamp - startTimestamp) / 1000}s `);
        }
      }
    })
  );
};
