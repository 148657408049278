import {computed, inject, Injectable, signal} from '@angular/core';
import {RoutesConstants} from '@core/constants/routes.constants';
import {BaseService} from '@core/services/base.service';
import {HttpParams} from '@angular/common/http';
import {AnonymousTokenDto} from '@shared/services/api/order-user.service';
import {catchError, EMPTY, filter, finalize, switchMap, take, tap} from 'rxjs';
import {AuthHelperService} from '../auth/auth-helper.service';
import {AuthService} from '@shared/services/auth/auth.service';
import {Basket, BasketEntity, BasketItem} from '@shared/models/entities/Basket.type';
import {ModalService, ModalType} from '@shared/components/modal/modal.service';
import {
  DeleteProductModalComponent
} from '@pages/basket/products-list/delete-product-modal/delete-product-modal.component';
import {GoodsListItem} from '@shared/models/entities/Goods.type';
import {PlatformDetectorService} from '@shared/services/platform-detector.service';


@Injectable({
  providedIn: 'root'
})
export class BasketService extends BaseService {
  private authHelperService = inject(AuthHelperService);
  private authService = inject(AuthService);
  private modalService = inject(ModalService);
  private pd = inject(PlatformDetectorService);

  basket = signal<Basket | null>(null);
  quantity = computed(() => {
    const basketItems = this.basket();
    if (!basketItems) {
      return 0;
    }

    return basketItems
      .filter(item => item.type === BasketItemType.GOODS)
      .reduce((acc, goods) => acc + (goods.quantity || 0), 0);
  });
  total = computed(() => this.basket()?.reduce((a, b) => a + b.priceAmount, 0) || 0);
  loading = signal(false);

  constructor() {
    super(RoutesConstants.BASKET.path);
  }

  init() {
    this.authService.isLoggedInOrAnonymous$.pipe(
      filter((logged) => logged && this.pd.isBrowser()),
      switchMap(() => this.getBasket()),
      take(1),
    ).subscribe()
  }

  getBasketItemByEntityId(entityId: number): BasketItem | null {
    return this.basket()?.find(basketItem => basketItem.entity.id === entityId) || null;
  }

  setToCard(count: number, entity: BasketEntity | GoodsListItem) {
    const {id} = entity;
    const basketItem = this.getBasketItemByEntityId(id);
    const isAdding = !basketItem && count > 0;
    const isRemoving = basketItem && count <= 0;
    const isChangingQuality = basketItem && count >= 1;

    if (isAdding) {
      this.addItemToBasket(count, id);
    } else if (isRemoving) {
      this.showDeleteItemFromBasketModal(entity);
    } else if (isChangingQuality) {
      this.changeItemQuantity(basketItem.id, count);
    } else {
      console.error('unknown command in setToCard');
    }

  }

  deleteItem(id: number) {
    return this.authHelperService.getAuthToken().pipe(
      switchMap(tokenDto => {
        const params = new HttpParams({fromObject: {...tokenDto}});

        return this.http.delete<Basket>(`${this.REST_PATH_V2}/item/${id}`, {params});
      }),
      catchError(err => this.handleError(err)),
      tap(basket => {
        this.basket.set(basket)
      })
    )
  }

  private getBasket() {
    this.loading.set(true);
    return this.authHelperService.getAuthToken().pipe(
      switchMap(tokenDto => {
        const params = new HttpParams({fromObject: {...tokenDto}});
        return this.http.get<Basket>(`${this.REST_PATH_V2}`, {params});
      }),
      catchError(this.handleError),
      tap(basket => {
        this.basket.set(basket)
      }),
      finalize(() => {
        this.loading.set(false);
      })
    );
  }

  private addItems(itemsDto: AddItemsToBasketDto) {
    this.loading.set(true);

    return this.authHelperService.getAuthToken().pipe(
      switchMap(tokenDto => {
        const body = {...itemsDto, ...tokenDto} satisfies AddItemsToBasketDto & AnonymousTokenDto;
        return this.http.post<Basket>(`${this.REST_PATH_V2}/items`, {...body});
      }),
      tap(basket => {
        this.basket.set(basket)
      }),
      catchError(error => {
        this.authService.anonymousLogout();
        return this.handleError(error);
      }),
      finalize(() => {
        this.loading.set(false);
      })
    )
  }

  private deleteBasket() {
    return this.authHelperService.getAuthToken().pipe(
      switchMap(tokenDto => {
        const params = new HttpParams({fromObject: {...tokenDto}});

        return this.http.delete(`${this.REST_PATH_V2}`, {params});
      }),
      catchError(this.handleError),
      tap(() => {
        this.basket.set(null)
      }),
    )
  }

  private changeQuantity(id: number, query: IncreaseQuantityQuery) {
    this.loading.set(true);

    return this.authHelperService.getAuthToken().pipe(
      switchMap(tokenDto => {
        const body = {...tokenDto, ...query};

        return this.http.put<Basket>(`${this.REST_PATH_V2}/item/${id}`, {...body});
      }),
      catchError(this.handleError),
      tap(basket => {
        this.basket.set(basket)
      }),
      finalize(() => {
        this.loading.set(false);
      })
    )
  }

  private addItemToBasket(amount: number, productId: number) {
    this.addItems({
      items: [{
        type: BasketItemType.GOODS,
        refId: productId,
        quantity: amount,
      }]
    }).pipe(take(1)).subscribe();
  }

  private showDeleteItemFromBasketModal(entity: BasketEntity) {
    this.modalService.show({
      component: DeleteProductModalComponent,
      inputs: {basketEntity: entity},
    }, ModalType.MODAL_CENTER);
  }

  private changeItemQuantity(itemId: number, quantity: number) {
    this.changeQuantity(itemId, {quantity}).pipe(take(1)).subscribe();
  }

  private handleError = (error: any) => {
    // Update basket with prev elements. Need to create new objects to trigger changes
    this.basket.update(prev => prev ? [...prev.map(p => ({...p}))] : null);
    return EMPTY;
  }
}

export type AddItemsToBasketDto = {
  items: AddItemToBasket[]
}

export interface AddItemToBasket {
  type: BasketItemType,
  refId: number;
  quantity: number;
}

export enum BasketItemType {
  GOODS = 'GOODS',
  BOX = 'BOX',
}

export interface IncreaseQuantityQuery {
  quantity: number;
}
