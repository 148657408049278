import {booleanAttribute, ChangeDetectionStrategy, Component, input} from '@angular/core';
import {NgClass, NgTemplateOutlet} from '@angular/common';

@Component({
    selector: 'app-button',
    imports: [
        NgClass,
        NgTemplateOutlet
    ],
    templateUrl: './button.component.html',
    styleUrl: './button.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  href = input<string>();
  buttonType = input<ButtonType>(ButtonType.PRIMARY);
  buttonSize = input<ButtonSize>(ButtonSize.BIG);
  disabled = input(false, {transform: booleanAttribute})
  fullWidth = input(false, {transform: booleanAttribute})

  get buttonClass() {
    return {
      'btn-primary': this.buttonType() === 'primary',
      'btn-secondary': this.buttonType() === 'secondary',
      'btn-tertiary': this.buttonType() === 'tertiary',
      'btn-neutral': this.buttonType() === 'neutral',
      'btn-big': this.buttonSize() === ButtonSize.BIG,
      'btn-medium': this.buttonSize() === ButtonSize.MEDIUM,
      'btn-small': this.buttonSize() === ButtonSize.SMALL,
      'full-width': this.fullWidth() ? 'full-width' : false,
      'disabled': this.disabled(),
    };
  }
}

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  NEUTRAL = 'neutral',
}

export enum ButtonSize {
  BIG = 'big',
  MEDIUM = 'medium',
  SMALL = 'small',
}

