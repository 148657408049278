import {computed, effect, inject, Injectable, signal} from '@angular/core';
import {User} from '@shared/models/entities/User.type';
import {BaseService} from '@core/services/base.service';
import {catchError, share, take, tap} from 'rxjs';
import {UserService} from '@shared/services/api/user.service';
import {LocalStorageService} from '@shared/services/storage/local-storage.service';
import {OrderUserService} from '@shared/services/api/order-user.service';
import {ANONYMOUS_TOKEN_KEY} from '@core/constants/local-storage-keys.constants';
import {toObservable} from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {
  private usersService = inject(UserService);
  private localStorageService = inject(LocalStorageService);
  private orderUserService = inject(OrderUserService);

  loadedUser = signal<User | null>(null);
  loadedUser$ = toObservable(this.loadedUser);
  isLoggedIn = computed(() => !!this.loadedUser());
  anonymousTokenDto = signal<{ anonymousToken: string } | null>(
    this.localStorageService.getData(ANONYMOUS_TOKEN_KEY)
      ? {
        anonymousToken: this.localStorageService.getData(ANONYMOUS_TOKEN_KEY) as string
      }
      : null
  );
  isLoggedInOrAnonymous = computed(() => this.isLoggedIn() || this.anonymousTokenDto() !== null);
  isLoggedInOrAnonymous$ = toObservable(this.isLoggedInOrAnonymous);

  constructor() {
    super('lokal')
    effect(() => {
      console.log(this.loadedUser())
    });

  }

  init() {
    return this.usersService.getCurrentUser().pipe(
      catchError((error) => {
        if (error.ok === 'Unauthorized') {
          this.logout()
            .pipe(take(1))
            .subscribe();
        }
        throw error;
      }),
      tap((usr) => {
        this.loadedUser.set(usr);

        // if(usr && this.anonymousTokenDto()) {
        //   this.localStorageService.removeData(ANONYMOUS_TOKEN_KEY);
        // }
      }),
      share(),
    );
  }

  logout(){
    return this.usersService.logout()
  }

  anonymousLogin() {
    return this.orderUserService.getAnonymousToken().pipe(
      tap(tokenDto => {
        this.localStorageService.saveData(ANONYMOUS_TOKEN_KEY, tokenDto.anonymousToken);
        this.anonymousTokenDto.set(tokenDto);
      })
    );
  }

  anonymousLogout() {
    this.localStorageService.removeData(ANONYMOUS_TOKEN_KEY);
    this.anonymousTokenDto.set(null);
  }
}
