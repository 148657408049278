import {computed, effect, inject, Injectable, OnDestroy, PLATFORM_ID, signal} from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {BreakpointsConstants} from '@core/constants/breakpoints.constants';

@Injectable({
  providedIn: 'root'
})
export class PlatformDetectorService implements OnDestroy {
  private readonly breakpoints = BreakpointsConstants;
  private resizeObserver: ResizeObserver | undefined;
  private platformId = inject(PLATFORM_ID);
  private document: Document = inject(DOCUMENT);

  currentScreenSize = signal<number | null>(null);
  isBrowser = signal<boolean>(isPlatformBrowser(this.platformId));
  isIOS = computed(() => this.isBrowser() && /iPad|iPhone|iPod/.test(navigator.platform));
  isMobileScreen = computed(() => {
    const currentScreenSize = this.currentScreenSize();
    return currentScreenSize !== null && this.isBrowser() && currentScreenSize <= this.breakpoints.md
  });
  isDesktopScreen = computed(() => {
    const currentScreenSize = this.currentScreenSize();
    return currentScreenSize !== null && this.isBrowser() && currentScreenSize >= this.breakpoints.xl
  });
  isServer = computed(() => !this.isBrowser());
  isDeskMid = computed(() => {
    const currentScreenSize = this.currentScreenSize();
    return currentScreenSize !== null
      && this.isBrowser()
      && currentScreenSize >= this.breakpoints.xl
      && currentScreenSize < this.breakpoints.xxl
  });
  isTabletScreen = computed(() => {
    const currentScreenSize = this.currentScreenSize();
    return currentScreenSize !== null
      && this.isBrowser()
      && currentScreenSize >= this.breakpoints.md
      && currentScreenSize < this.breakpoints.xl
  });
  currentScreen = computed(() => {
    return (this.isMobileScreen() && Screens.MOBILE)
      || (this.isDesktopScreen() && Screens.DESKTOP)
      || (this.isTabletScreen() && Screens.TABLET)
      || Screens.UNKNOWN
  })

  constructor() {
    effect(() => {
      // TODO: to figure out why not working without effect
      this.currentScreen()
    });
    this.resizeObserver = this.isBrowser()
      ? new ResizeObserver(() => {
        this.currentScreenSize.set((this.document.defaultView as Window).innerWidth);
      })
      : undefined;

    this.resizeObserver?.observe(this.document.body);
  }

  ngOnDestroy() {
    this.resizeObserver?.unobserve(this.document.body);
  }
}

export enum Screens {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  UNKNOWN = 'unknown'
}
