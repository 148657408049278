import {inject, Injectable} from '@angular/core';
import {UrlService} from '@shared/services/url.service';
import {Language} from '@shared/models/common/Language.enum';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  private urlService = inject(UrlService);
  getLocale() {
    const url = this.urlService.getCurrentUrl();

    // Check the URL for each language indicator
    for (const [_, locale] of Object.entries(Language)) {
      if (new RegExp(`/${locale}(\/|$)`).test(url)) {
        return locale;
      }
    }

    // Default fallback
    return Language.UK; // or any default language you prefer
  }
}

export interface TranslationGroup {
  [ key: string ]: Translation;
}

export type Translation = {
  [key in Language]: string;
}

