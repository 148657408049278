import {inject, Injectable, signal} from '@angular/core';
import {BaseService} from '@core/services/base.service';
import {GoodsListItem} from '@shared/models/entities/Goods.type';
import {filter, Observable, switchMap, take} from 'rxjs';
import {AuthService} from '@shared/services/auth/auth.service';
import {PlatformDetectorService} from '@shared/services/platform-detector.service';

@Injectable({
  providedIn: 'root'
})
export class WishlistService extends BaseService {
  private authService = inject(AuthService);
  private pd = inject(PlatformDetectorService);
  private _wishlist = signal<GoodsListItem[] | null>(null);
  wishlist = this._wishlist.asReadonly();

  constructor() {
    super('wishlist');
  }

  getWishlist(): Observable<GoodsListItem[]> {
    return this.http.get<GoodsListItem[]>(`${this.REST_PATH_V2}`);
  }

  setWishlistGoods(id: number) {
    return this.http.post(`${this.REST_PATH}/goods/${id}/set`, {});
  }

  setWishlist(goods: GoodsListItem[] | null) {
    this._wishlist.set(goods);
    return this.wishlist;
  }

  init() {
    this.authService.loadedUser$.pipe(
      filter(() => this.pd.isBrowser()),
      filter(loadedUser => loadedUser !== null),
      switchMap(() => this.getWishlist()),
      take(1),
    ).subscribe(wishlist => {
      this.setWishlist(wishlist);
    });
  }
}
