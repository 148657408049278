import { Injectable, signal } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class StorageService {
  protected storage = signal<Storage | undefined>(undefined);

  public saveData(key: string, value: string) {
    this.storage()?.setItem(key, value);
  }

  public getData(key: string) {
    return this.storage()?.getItem(key);
  }
  public removeData(key: string) {
    this.storage()?.removeItem(key);
  }

  public clearData() {
    this.storage()?.clear();
  }
}
