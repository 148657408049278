{
  "general": {
    "currency": "грн",
    "grams": "г",
    "name": "Львівська майстерня шоколаду",
    "test": "Тест"
  },
  "common": {
    "contactPhone": "0 800 50 8338"
  },
  "home": {
    "popular": "Популярні",
    "collections": "Колекції",
    "watchAll": "Дивитися всі",
    "goodsCategories": "Категорії товарів"
  },
  "footer": {
    "account": "Акаунт",
    "copyrights": "© Львівська майстерня шоколаду"
  },
  "burgerMenu": {
    "loginIntoAccount": "Увійти в акаунт",
    "favorite": "Улюблене"
  },
  "login": {},
  "socials": {
    "facebook": "https://www.facebook.com/LvivHandmadeChocolate",
    "instagram": "https://www.instagram.com/lviv.chocolate/",
    "youtube": "https://www.youtube.com/channel/UCnKOz33UE-FDovqLzWfCW_g"
  },
  "cart": {
    "buttons": {
      "add": "Додати в кошик",
      "remove": "Видалити з кошика",
      "checkout": "Оформити"
    }
  }
}