import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {IconButtonComponent} from '@shared/components/ui/buttons/icon-button/icon-button.component';
import {IconComponent, IconSize} from '@shared/components/ui/icons/icon/icon.component';
import {ModalService} from '@shared/components/modal/modal.service';

@Component({
    selector: 'app-modal-wrapper',
    imports: [
        IconButtonComponent,
        IconComponent
    ],
    templateUrl: './modal-wrapper.component.html',
    styleUrl: './modal-wrapper.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalWrapperComponent {
  private modalService = inject(ModalService);

  close() {
    this.modalService.hideTooltip();
  }

  protected readonly IconSize = IconSize;
}
