
import { HttpInterceptorFn } from '@angular/common/http';
import {inject} from '@angular/core';
import {LocaleService} from '@shared/services/locale.service';
import {environment} from '@env/environment';

export const langToApiReqInterceptor: HttpInterceptorFn = (req, next) => {

  const locale = inject(LocaleService).getLocale();

  if (req.url.startsWith(environment.api) && req.method === 'GET' && !req.url.includes('lang=')) {
    const cloned = req.clone({
      params: req.params.set('lang', locale)
    });

    return next(cloned);
  }

  return next(req);
};
