@if (href(); as href) {
  <a class="button" [ngClass]="buttonClass" [href]="href">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
} @else {
  <button class="button" [ngClass]="buttonClass">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
}
<ng-template #content>
  <ng-content></ng-content>
</ng-template>

