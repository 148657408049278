@let item = basketItem();

<app-modal-wrapper>
  <aside class="modal">
    <app-modal-title>{{ 'Ви дійсно бажаєте видалити цей товар з кошику?' }}</app-modal-title>
    @if (item) {
      <app-product-to-delete [basketEntity]="item"></app-product-to-delete>
    }
    <div class="buttons-group">
      <app-button (click)="delete(basketEntity().id)" fullWidth [buttonType]="ButtonType.PRIMARY"><span>{{'Видалити'}}</span></app-button>
      <app-button (click)="close()" fullWidth [buttonType]="ButtonType.SECONDARY"><span>{{'Скасувати'}}</span></app-button>
    </div>
  </aside>
</app-modal-wrapper>

