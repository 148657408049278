import {CanActivateFn} from '@angular/router';
import {RoutesConstants} from '@core/constants/routes.constants';
import {inject} from '@angular/core';
import {AuthService} from '@shared/services/auth/auth.service';
import {PlatformDetectorService} from '@shared/services/platform-detector.service';
import {WINDOW} from '@core/tokens/Window.token';
import {CreateRoutePipe} from '@shared/pipes/create-route.pipe';

export const authOrAnonymousGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const pd = inject(PlatformDetectorService);
  const window = inject(WINDOW);
  const createRouter = inject(CreateRoutePipe);

  if(pd.isServer()) {
    return true;
  }

  if (authService.isLoggedInOrAnonymous()) {
    return true;
  }

  if (pd.isBrowser()) {
    window?.location.replace(createRouter.transform([RoutesConstants.PROFILE.path, RoutesConstants.PROFILE.children.LOGIN_LOKAL.path]))
  }

  return false;
};
