{
  "general": {
    "currency": "UAH",
    "grams": "g",
    "name": "LVIV HANDMADE CHOCOLATE",
    "test": "Test"
  },
  "common": {
    "contactPhone": "0 800 50 8338"
  },
  "home": {
    "popular": "Popular",
    "collections": "Collections",
    "watchAll": "show all",
    "goodsCategories": "Product Categories"
  },
  "footer": {
    "account": "My Account",
    "copyrights": "© LVIV HANDMADE CHOCOLATE"
  },
  "burgerMenu": {
    "loginIntoAccount": "Login",
    "favorite": "Улюблене"
  },
  "login": {},
  "socials": {
    "facebook": "https://www.facebook.com/LvivHandmadeChocolate",
    "instagram": "https://www.instagram.com/lviv.chocolate/",
    "youtube": "https://www.youtube.com/channel/UCnKOz33UE-FDovqLzWfCW_g"
  },
  "cart": {
    "buttons": {
      "add": "Add to cart",
      "remove": "Remove from Cart",
      "checkout": "Checkout"
    }
  }
}