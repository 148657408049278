import {inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Meta, Title} from '@angular/platform-browser';
import {filter, Subject, switchMap} from 'rxjs';
import {tap} from 'rxjs/operators';
import {PlatformDetectorService} from './platform-detector.service';
import {LOCALE} from '@core/tokens/Locale.token';
import {PageDataService} from '@shared/services/api/page-data.service';
import {Language} from '@shared/models/common/Language.enum';
import {Breadcrumbs, NewsArticle, OgTags, TwitterTags} from '@shared/models/entities/PageData.type';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  private dom = inject(DOCUMENT);
  private locale = inject(LOCALE);
  private title = inject(Title);
  private pd = inject(PlatformDetectorService);
  private meta = inject(Meta);
  private pageDataService = inject(PageDataService);

  private SITE_NAME = 'LMSH';

  private _init$ = new Subject<void>();

  constructor() {
    this._init$.pipe(
      tap(() => {
        this.setDocumentLang(this.locale);
      }),
      switchMap(() => this.processPageData())
    ).subscribe();
  }

  init() {
    this._init$.next();
  }

  private processPageData() {
    return this.pageDataService.pageData$
      .pipe(
        tap((pageData) => {
          this.title.setTitle(`${(pageData.seoTitle || pageData.title)} – ${this.SITE_NAME}`);
          this.meta.addTag({name: 'description', content: pageData.seoDescription || pageData.description});
          this.createCanonicalURL(pageData.canonicalUrl);
          this.createAlternate(pageData.alternateUa, pageData.alternateEn);
          this.initOgData(pageData.ogTags);
          this.initTwitterTags(pageData.twitterTags);
        }),
        filter(() => this.pd.isServer()),
        tap(pageData => {
          this.initLdJsonPageData(pageData.breadcrumbs);
        })
      );
  }

  private setDocumentLang(lang: Language) {
    this.dom.documentElement.lang = lang;
  }

  private createCanonicalURL(url: string) {
    let link = this.dom.querySelector('link[rel="canonical"]');

    if (!link) {
      link = this.dom.createElement('link');
      this.dom.head.appendChild(link);
    }

    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', url);
  }

  private createAlternate(urlUa: string | null, urlEn: string | null) {
    if (!urlUa || !urlEn) return;

    const linkUa = this.dom.querySelector(`link[rel="alternate"][hreflang="${Language.UK}"]`);
    const linkEn = this.dom.querySelector(`link[rel="alternate"][hreflang="${Language.EN}"]`);

    create(linkUa, Language.UK, urlUa, this);
    create(linkEn, Language.EN, urlEn, this);

    function create(link: Element | null, locale: Language, url: string, _this: SeoService) {
      let linkElement = link;
      if (!linkElement) {
        linkElement = _this.dom.createElement('link');
        _this.dom.head.appendChild(linkElement);
      }
      linkElement.setAttribute('rel', 'alternate');
      linkElement.setAttribute('hreflang', locale);
      linkElement.setAttribute('href', url);
    }
  }

  private initLdJsonPageData(ldJson: Breadcrumbs | NewsArticle | null | undefined) {
    if (!ldJson) return;
    const scriptEl = this.dom.createElement('script');
    scriptEl.type = 'application/ld+json';
    scriptEl.text = `${JSON.stringify(ldJson)}`;
    this.dom.head.appendChild(scriptEl);
  }

  private initOgData(ogData: OgTags | null): void {
    if (!ogData) return;
    Object.keys(ogData).forEach(key => {
      this.meta.updateTag({property: key, content: ogData[key].content});
    });
  }

  private initTwitterTags(twitterData: TwitterTags | null): void {
    if (!twitterData) return;
    Object.keys(twitterData).forEach(key => {
      this.meta.updateTag({property: key, content: twitterData[key].content});
    });
  }
}
