import {inject, Pipe, PipeTransform} from '@angular/core';
import {LOCALE} from '@core/tokens/Locale.token';
import * as EN_TRANSLATIONS from '@translations/messages.en.json';
import * as UK_TRANSLATIONS from '@translations/messages.json';
import {Language} from '@shared/models/common/Language.enum';


@Pipe({
  name: 'translate',
  standalone: true
})
export class TranslatePipe implements PipeTransform {
  private locale = inject(LOCALE);

  // A map of language to translation objects
  private readonly translationFilesMap: Map<Language, object> = new Map([
    [Language.EN, EN_TRANSLATIONS],
    [Language.UK, UK_TRANSLATIONS],
  ]);

  // The transform function that handles translating the key
  transform(key: string): string {
    const translations = this.translationFilesMap.get(this.locale);
    if (!translations) return key;
    return this.getNestedValue(translations, key) || key; // Fallback to the key itself if not found
  }

  // Recursive function to handle nested keys with varying depths
  private getNestedValue(obj: object, key: string): any {
    if (!obj) return undefined;

    const keys = key.split('.');
    let currentValue: object | string = obj;

    for (const part of keys) {
      if (currentValue && typeof currentValue === 'object') {
        currentValue = currentValue[part as keyof object] as object | string;
      } else {
        return undefined; // Return undefined if any key is not found
      }
    }

    return typeof currentValue === 'string' ? currentValue : JSON.stringify(currentValue);
  }
}
