import {inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class WindowProviderService {
  private document = inject(DOCUMENT);

  get nativeWindow(): Window | null {
    return this.document.defaultView;
  }
}
