@if (href()) {
  <a class="button" [ngClass]="{disabled: disabled()}" [href]="href()" [attr.aria-label]="ariaLabel()">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
} @else {
  <button class="button" [ngClass]="{disabled: disabled()}" [attr.aria-label]="ariaLabel()">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
}

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
