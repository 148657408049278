import {ChangeDetectionStrategy, Component, computed, inject, input} from '@angular/core';
import {ButtonComponent, ButtonType} from '@shared/components/ui/buttons/button/button.component';
import {ModalTitleComponent} from '@shared/components/modal/modal-title/modal-title.component';
import {ModalWrapperComponent} from '@shared/components/modal/modal-wrapper/modal-wrapper.component';
import {ModalService} from '@shared/components/modal/modal.service';
import {
  ProductToDeleteComponent
} from '@pages/basket/products-list/delete-product-modal/product-to-delete/product-to-delete.component';
import {BasketService} from '@shared/services/api/basket.service';
import {take} from 'rxjs';
import {BasketEntity} from '@shared/models/entities/Basket.type';

@Component({
  selector: 'app-delete-product-modal',
  imports: [
    ButtonComponent,
    ModalTitleComponent,
    ModalWrapperComponent,
    ProductToDeleteComponent
  ],
  templateUrl: './delete-product-modal.component.html',
  styleUrl: './delete-product-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteProductModalComponent {
  private modalService = inject(ModalService);
  private basketService = inject(BasketService);
  basketEntity = input.required<BasketEntity>();
  basketItem = computed(() => {
    return this.basketService.basket()?.find(basketItem => basketItem.entity.id === this.basketEntity().id);
  });

  delete(id: number) {
    const basketItem = this.basketItem();
    if (!basketItem) {
      console.error('BasketItem not found.');
      return;
    }
    this.basketService.deleteItem(basketItem.id)
      .pipe(take(1))
      .subscribe(() => {
        this.modalService.hideTooltip();
      })
  }

  close() {
    this.modalService.hideTooltip();
  }


  protected readonly ButtonType = ButtonType;
}
