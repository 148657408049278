import {inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {PlatformDetectorService} from './platform-detector.service';
import {REQUEST} from '@core/tokens/Request.token';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  private pd = inject(PlatformDetectorService);
  private request = inject(REQUEST, {optional: true});
  private document = inject(DOCUMENT);

  getCurrentUrl(): string {
    return this.document.location.href;
  }
}
