import {Injectable} from '@angular/core';
import {BaseService} from '@core/services/base.service';
import {User} from '@shared/models/entities/User.type';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  constructor() {
    super('users')
  }

  getCurrentUser() {
      return this.http.get<User>(`${this.REST_PATH}/current`);
  }

  update(user: Omit<User, 'id'>) {
    return this.http.put(`${this.REST_PATH}/update`, user);
  }

  logout() {
    return this.http.post(`${this.REST_PATH_V2}/logout`, {});
  }

}
