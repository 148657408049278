import {inject, Injectable, Injector, signal, Type} from '@angular/core';
import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {ModalComponent} from '@shared/components/modal/modal.component';
import {MODAL_DATA} from '@shared/components/modal/modal-data.token';
import {PlatformDetectorService, Screens} from '@shared/services/platform-detector.service';
import {WINDOW} from '@core/tokens/Window.token';
import {filter, fromEvent, Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private overlayRef: OverlayRef | null = null;
  private pd = inject(PlatformDetectorService);
  private window = inject(WINDOW);
  private subscriptions: Subscription[] = [];
  isOpened = signal(false);

  constructor(private overlay: Overlay, private injector: Injector) {
  }

  show({component, inputs, injector}: ModalData, type: ModalType = ModalType.MODAL, origin?: HTMLElement) {
    const modalTypes = [ModalType.MODAL, ModalType.TOP_STICKY, ModalType.MODAL_CENTER];

    if (modalTypes.includes(type)) {
      this.showModal({component, inputs, injector}, type);
    } else if (origin) {
      this.showTooltip(origin, {component, inputs, injector}, type);
    }
  }

  private showModal({component, inputs, injector}: ModalData, type: ModalType) {
    // Dispose of any existing overlay
    this.hideTooltip();

    const escapeSubscription = this.initEscapeListener();
    if (escapeSubscription) {
      this.subscriptions.push(escapeSubscription);
    }

    const overlayConfig: OverlayConfig = this.getModalConfigByType(type);
    this.overlayRef = this.overlay.create(overlayConfig);

    const customInjector = Injector.create({
      parent: this.injector,
      providers: [
        {provide: MODAL_DATA, useValue: {component, inputs, injector}},
        {provide: OverlayRef, useValue: this.overlayRef},
      ]
    });


    const portal = new ComponentPortal(ModalComponent, null, customInjector);
    const modalRef = this.overlayRef?.attach(portal);
    this.overlayRef?.updatePosition();

    this.showWithoutAnimation();

    return modalRef;
  }

  private showTooltip(origin: HTMLElement, {
    component,
    inputs,
    injector
  }: ModalData, type: ModalType = ModalType.MODAL) {
    // Dispose of any existing overlay
    this.hideTooltip();

    const escapeSubscription = this.initEscapeListener();
    if (escapeSubscription) {
      this.subscriptions.push(escapeSubscription);
    }

    const overlayConfig: OverlayConfig = this.getTooltipConfigByType(type, origin);
    this.overlayRef = this.overlay.create(overlayConfig);

    const customInjector = Injector.create({
      parent: this.injector,
      providers: [
        {provide: MODAL_DATA, useValue: {component, inputs, injector}},
        {provide: OverlayRef, useValue: this.overlayRef},
      ]
    });

    const portal = new ComponentPortal(ModalComponent, null, customInjector);
    const modalRef = this.overlayRef?.attach(portal);
    this.overlayRef?.updatePosition();
    this.showWithAnimation();

    return modalRef;
  }

  hideTooltip() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    this.isOpened.set(false);
  }

  initEscapeListener(): Subscription | undefined {
    if (!this.window) {
      return;
    }
    return fromEvent<KeyboardEvent>(this.window, 'keyup')
      .pipe(
        filter((event: KeyboardEvent) => event.key === 'Escape'),
      )
      .subscribe(() => this.hideTooltip());
  }

  getModalConfigByType(type: ModalType) {
    const configMap = new Map<ModalType, OverlayConfig>([
      [
        ModalType.MODAL,
        (() => ({
          hasBackdrop: true,
          backdropClass: 'modal-backdrop',
          panelClass: 'modal',
          maxWidth: 'calc(100% - 3.2rem)',
          positionStrategy: this.overlay.position().global().centerHorizontally().top(this.pd.currentScreen() === Screens.MOBILE ? '50px' : '250px')
        }))()
      ],
      [
        ModalType.MODAL_CENTER,
        (() => ({
          hasBackdrop: true,
          backdropClass: 'modal-backdrop',
          panelClass: 'modal',
          maxWidth: 'calc(100% - 3.2rem)',
          positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically()
        }))()
      ],
      [
        ModalType.TOP_STICKY,
        (() => {
          const positionStrategy = this.overlay.position()
            .global()
            .top('0px') // Stick to the top
            .centerHorizontally(); // Full width centered horizontally

          return new OverlayConfig({
            hasBackdrop: true, // Optional, based on your design needs
            backdropClass: 'modal-backdrop',
            panelClass: 'top-sticky',
            positionStrategy,
            width: '100%', // Ensure full width
            scrollStrategy: this.overlay.scrollStrategies.block(), // Prevent scroll when modal is open
          });
        })()
      ],
    ]);
    return configMap.get(type) || {};
  }

  getTooltipConfigByType(type: ModalType, origin: HTMLElement) {
    const configMap = new Map<ModalType, OverlayConfig>([
      [
        ModalType.TOOLTIP,
        (() => {
          const positionStrategy = this.overlay.position()
            .flexibleConnectedTo(origin)
            .withPositions([
              {
                originX: 'end',
                originY: 'bottom',
                overlayX: 'end',
                overlayY: 'top',
                offsetY: 8,
                offsetX: 16,
              }
            ]);

          return new OverlayConfig({
            hasBackdrop: true,
            backdropClass: 'tooltip-backdrop',
            panelClass: 'tooltip',
            positionStrategy,
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
          });
        })()
      ],
      [
        ModalType.MENU,
        (() => {
          const positionStrategy = this.overlay.position()
            .flexibleConnectedTo(origin)
            .withFlexibleDimensions(false)
            .withPositions([
              {
                originX: 'start',
                originY: 'bottom',
                overlayX: 'start',
                overlayY: 'top',
                offsetY: 14,
              }
            ])
            .withDefaultOffsetX(0)
            .withDefaultOffsetY(14);

          const overlayWidth = origin.offsetWidth;

          return new OverlayConfig({
            hasBackdrop: true,
            backdropClass: 'menu-backdrop',
            panelClass: 'menu',
            positionStrategy,
            width: `${overlayWidth}px`, // Set the overlay's width
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
          });
        })()
      ]
    ]);
    return configMap.get(type) || {};
  }

  private applyAnimationClass(isShowing: boolean) {
    if (this.overlayRef) {
      const panelElement = this.overlayRef.hostElement.querySelector('app-modal');
      if (panelElement) {
        if (isShowing) {
          panelElement.classList.remove('hide');
          panelElement.classList.add('show');
        } else {
          panelElement.classList.remove('show');
          panelElement.classList.add('hide');
        }
      }
    }
  }

  private showWithAnimation() {
    // Apply the show animation
    this.applyAnimationClass(true);

    this.subscriptions.push(this.overlayRef!.backdropClick().subscribe(() => {
      // Apply the hide animation
      this.applyAnimationClass(false);

      setTimeout(() => {
        this.hideTooltip();
        this.isOpened.set(false);
      }, 200); // Match animation duration in _global.scss

    }));

    this.isOpened.set(true);
  }

  private showWithoutAnimation() {
    this.subscriptions.push(this.overlayRef!.backdropClick().subscribe(() => {
      this.hideTooltip();
      this.isOpened.set(false);
    }));
    this.isOpened.set(true);
  }

}

export interface ModalData {
  component: Type<unknown>;
  inputs: Record<string, unknown>
  injector?: Injector;
}

export enum ModalType {
  MODAL = 'modal',
  MODAL_CENTER = 'modal-center',
  TOP_STICKY = 'top-sticky',
  TOOLTIP = 'tooltip',
  MENU = 'menu',
}
