import { Injectable } from '@angular/core';
import {BaseService} from '@core/services/base.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderUserService extends BaseService {

  constructor() {
    super('order-user');
  }

  getAnonymousToken(): Observable<AnonymousTokenDto> {
    return this.http.get<AnonymousTokenDto>(`${this.REST_PATH_V2}/anonymous-token`);
  }
}

export interface AnonymousTokenDto {
  anonymousToken: string
}
