import { inject, Injectable } from '@angular/core';
import { AuthService } from '@shared/services/auth/auth.service';
import { AnonymousTokenDto } from '@shared/services/api/order-user.service';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthHelperService {
  private authService = inject(AuthService);

  // Method to check and get the token if necessary
  getAuthToken(): Observable<AnonymousTokenDto> {
    const isLoggedIn = this.authService.isLoggedInOrAnonymous;
    const anonymousTokenDto = this.authService.anonymousTokenDto;

    if (!isLoggedIn()) {
      return this.authService.anonymousLogin().pipe(
        switchMap(token => {
          return new Observable<AnonymousTokenDto>(observer => {
            observer.next(token);
            observer.complete();
          });
        })
      );
    }

    return new Observable<AnonymousTokenDto>(observer => {
      observer.next(anonymousTokenDto() as AnonymousTokenDto);
      observer.complete();
    });
  }
}
