import {
  ApplicationConfig,
  inject,
  provideAppInitializer,
  provideExperimentalZonelessChangeDetection,
  Provider
} from '@angular/core';
import {provideRouter, Routes} from '@angular/router';
import {provideClientHydration, withEventReplay, withIncrementalHydration} from '@angular/platform-browser';
import {provideHttpClient, withFetch, withInterceptors} from '@angular/common/http';
import {LocaleService} from '@shared/services/locale.service';
import {LOCALE} from '@core/tokens/Locale.token';
import {langToApiReqInterceptor} from '@core/interceptors/lang-to-api-req.interceptor';
import {LanguagePipe} from '@shared/pipes/language.pipe';
import {WINDOW} from '@core/tokens/Window.token';
import {WindowProviderService} from '@shared/services/window-provider.service';
import {CreateRoutePipe} from '@shared/pipes/create-route.pipe';
import {routes} from './app.routes';
import {jwtInterceptor} from '@core/interceptors/jwt.interceptor';
import {AuthService} from '@shared/services/auth/auth.service';
import {TranslatePipe} from '@shared/pipes/translate.pipe';
import {CreateCategoryRoutePipe} from '@pages/categories/create-category-route.pipe';
import {httpResponseTimeLogger} from '@core/interceptors/http-response-time-logger';

export const baseRouter: Routes = [
  {
    path: '',
    loadComponent: () => import('@shared/components/layouts/main-layout/main-layout.component').then(c => c.MainLayoutComponent),
    children: routes
  },
];

const customProviders: Provider[] = [
  {
    provide: LOCALE,
    useFactory: (localeService: LocaleService) => {
      return localeService.getLocale();
    },
    deps: [LocaleService],
  },
  {
    provide: WINDOW,
    useFactory: (windowProviderService: WindowProviderService) => windowProviderService.nativeWindow,
    deps: [WindowProviderService]
  },
]
const globalProviders = [LanguagePipe, CreateRoutePipe, CreateCategoryRoutePipe, TranslatePipe];

export const appConfig: ApplicationConfig = {
  providers: [
    provideExperimentalZonelessChangeDetection(),
    provideRouter(
      baseRouter
    ),
    provideClientHydration(withEventReplay(), withIncrementalHydration()),
    provideHttpClient(withFetch(), withInterceptors([langToApiReqInterceptor, jwtInterceptor, httpResponseTimeLogger])),
    ...customProviders,
    ...globalProviders,
    provideAppInitializer(userInitializer),
  ],
};


function userInitializer() {
  const authService = inject(AuthService);
  return new Promise((resolve) => {
    authService.init().subscribe({
      next: () => {
        resolve(true);
      },
      error: () => {
        resolve(false);
      },
    });
  });
}

