import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslatableField} from '@shared/models/partial/TranslatableField.type';
import {Language} from '@shared/models/common/Language.enum';
import {LOCALE} from '@core/tokens/Locale.token';

@Pipe({
  name: 'language',
  standalone: true
})
export class LanguagePipe implements PipeTransform {
  private locale = inject(LOCALE);
  private UK_LOCATION_ID = 1;
  private EN_LOCATION_ID = 18;

  transform(value: TranslatableField | null): string | undefined {
    if(!value) {
      return;
    }
    const locationId = this.locale === Language.UK ? this.UK_LOCATION_ID : this.EN_LOCATION_ID;
    return value.translations.find(t => t.language === locationId)?.text;
  }

}
