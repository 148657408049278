import {HttpInterceptorFn} from '@angular/common/http';
import {inject} from '@angular/core';
import {REQUEST} from '@core/tokens/Request.token';


export const jwtInterceptor: HttpInterceptorFn = (req, next) => {
  const request = inject(REQUEST, { optional: true });
  // Retrieve cookies from the request object
  const cookies = request?.headers?.cookie;

  const authReq = req.clone({
    withCredentials: true,
    setHeaders: {
      ...(cookies ? { Cookie: cookies } : {}), // Correct header name for cookies
    },
  });

  return next(authReq);
};
