import {Component, inject, OnDestroy, ViewEncapsulation} from '@angular/core';
import {MODAL_DATA} from '@shared/components/modal/modal-data.token';
import {NgComponentOutlet} from '@angular/common';
import {ModalData, ModalService} from '@shared/components/modal/modal.service';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [
        NgComponentOutlet
    ]
})
export class ModalComponent implements OnDestroy {
  private modalService = inject(ModalService);
  data = inject<ModalData>(MODAL_DATA);

  ngOnDestroy() {
    this.close();
  }

  close() {
    this.modalService.hideTooltip();
  }
}
